import React, { useEffect, useState } from "react";
import Benifits from "../../components/dao/Benifits";
import Intro from "../../components/dao/Intro";
import Participate from "../../components/dao/Participate";
import NewseLatter2 from "../../components/dao/newseLatter2";
import { Partners } from "../../components/component";
import Hero_4 from "../../components/hero/hero_4";
import Meta from "../../components/Meta";
import TrustedPartner from "../../components/dao/TrustedPartner";
import Features from "../../components/features/features";
import Link from "next/link";
import Promo from "../../components/promo/promo";
import ImageTitle from "../../components/imageTitle";
import ContactForm from "../../components/contact/ContactForm";
import Address from "../../components/contact/address";
import Calltoaction from "../../components/calltoaction";
import { Star } from "react-feather";
import { Tooltip } from "@nextui-org/react";

const Home_9 = () => {
  useEffect(() => {
    const header = document.querySelector("header");
    header.classList.add("bg-white/[.05]");
  }, []);

  const [videoModal, setvideoModal] = useState(false);
  const [url, setUrl] = useState(null);

  return (
    <>
      <Meta title="CIRSYS Tech | Desarrollamos tecnología para el beneficio social." desc={"En CIRSYS buscamos crear tecnología para un mundo más sostenible. Somos una empresa que aporta valor a los negocios desarrollando proyectos con Inteligencia Artificial, Robotica Social, Automatización, desarrollo de Hardware y Software."} keyword={"Inteligencia Artificial, Robotica Social, Automatización, desarrollo de Hardware y Software, proyectos, irbin, cirsys, cirsys tech, tecnología, medio ambiente"} />

      <Hero_4 />

      {/* <Partners /> */}

      <Features />

      {/* CASES / PROYECTOS */}
      <div className="mx-auto mt-24 mb-14 max-w-xl text-center">
        <h2 className="mb-6 text-center font-display text-3xl font-medium text-white md:text-5xl">
          Nuestros proyectos
        </h2>
        <p className="text-lg text-jacarta-300 dark:text-jacarta-300 px-4">
          Ofrecemos una variedad de servicios orientados a la necesidad del cliente y a la medida de lo que requiera.
        </p>
      </div>

      <div className="container pb-40">
        {/* IRBIN */}
        <article className="mb-[1.875rem] md:mb-16">
          <div className="rounded-2xl dark:rounded-2xl flex flex-col overflow-hidden md:flex-row border dark:border-accent border-accent hidden-safari">
            <figure className="overflow-hidden md:w-1/2">
              <Link href="/irbin">
                <a>
                  <img
                    src="/images/blog/case-irbin.png"
                    alt="IRBin"
                    className="h-full w-full object-cover transition-transform duration-[500ms] will-change-transform hover:scale-105 md:rounded-l-2xl rounded-l-none rounded-t-2xl md:rounded-t-none"
                    style={{
                      verticalAlign: 'middle'
                    }}
                  />
                </a>
              </Link>
            </figure>

            {/* <!-- Body --> */}
            <div className="dark:bg-jacarta-1000 bg-jacarta-1000 rounded-b-[1.25rem] p-[10%] md:w-1/2 md:rounded-none md:rounded-r-[1.25rem]">
              <div className="mb-5"
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  className="border border-accent dark:border-accent">
                  <Star fill="#ADFF77" size={15} color='#ADFF77' className="mr-2" />
                  <p className="text-accent text-xs">
                    PROYECTO DESTACADO
                  </p>
                </div>
              </div>
              {/* <!-- Meta --> */}
              <div className="mb-3 flex flex-wrap items-center space-x-1 text-xs">
                <p className="dark:text-jacarta-200 text-jacarta-200">
                  CIRSYS TECH
                </p>
              </div>

              <h2 className="font-display mb-4 text-xl dark:text-white text-white sm:text-3xl">
                IRBin
              </h2>
              <p className="dark:text-jacarta-200 text-jacarta-200 mb-10">
                El primer robot social con inteligencia artificial para el reciclaje en Perú.
              </p>

              <div className="inline-flex space-x-4">
                <Link href="/irbin">
                  <a className="bg-accent hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-black ease-out duration-200">
                    VER MÁS
                  </a>
                </Link>
              </div>

              <div className="flex flex-row mt-10 ">
                <p className="pr-3 dark:text-jacarta-200 text-jacarta-200">PREMIOS</p>
                <Tooltip
                  className="mr-3 tooltip"
                  content={
                    <div className="text-start">
                      <span>El Ojo de Iberoamérica:</span>
                      <br />
                      <span>1x BRONCE</span>
                    </div>
                  }
                  color="invert"
                >
                  <img
                    src="/images/awards/awards/premio-ojo.svg"
                    alt="awards"
                    className="w-[auto] h-[20px]"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </article>

        {/* E-nterpreters 2 */}
        <article className="mb-[1.875rem] md:mb-16">
          <div className="rounded-2xl dark:rounded-2xl flex flex-col overflow-hidden md:flex-row hidden-safari">


            {/* <!-- Body --> */}
            <div className="dark:bg-jacarta-1000 bg-jacarta-1000 p-[10%] md:w-1/2 md:rounded-none md:rounded-l-[1.25rem]">
              {/* <!-- Meta --> */}
              <div className="mb-3 flex flex-wrap items-center space-x-1 text-xs">
                <p className="dark:text-jacarta-200 text-jacarta-200">
                  PILSEN CALLAO
                </p>
              </div>

              <h2 className="font-display mb-4 text-xl dark:text-white text-white sm:text-3xl">
                E-nterpreters 2
              </h2>
              <p className="dark:text-jacarta-200 text-jacarta-200 mb-6">
                Nuevo interfaz gráfico para E-nterpreters y nueva funcionalidad de lectura de labios para darle voz a personas con discapacidad del habla.
              </p>
              <div className="mb-10"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #GAMING
                  </p>
                </div>
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #IA
                  </p>
                </div>
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #LECTURA
                  </p>
                </div>
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #LABIOS
                  </p>
                </div>
              </div>

              <div className="inline-flex space-x-4">
                <button onClick={() => { setvideoModal(true); setUrl("https://www.youtube.com/embed/KXcuLUESPSg"); }} className="bg-accent hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-black ease-out duration-200">
                  VER MÁS
                </button>
              </div>
            </div>

            <figure className="group overflow-hidden md:w-1/2 cursor-pointer">
              <a onClick={() => { setvideoModal(true); setUrl("https://www.youtube.com/embed/KXcuLUESPSg"); }}>
                <img
                  src="/images/blog/case-enterpreters2.png"
                  alt="Smart Retro Device"
                  className="h-full w-full object-cover transition-transform duration-[500ms] will-change-transform hover:scale-105 md:rounded-r-2xl rounded-r-none rounded-b-2xl md:rounded-b-none"
                />
              </a>
            </figure>
          </div>
        </article>

        {/* Turning Waste into Low Prices */}
        <article className="mb-[1.875rem] md:mb-16">
          <div className="rounded-2xl dark:rounded-2xl flex flex-col overflow-hidden md:flex-row hidden-safari">
            <figure className="group overflow-hidden md:w-1/2  cursor-pointer">
              <a onClick={() => { setvideoModal(true); setUrl("https://www.youtube.com/embed/m-vT6b37_7E"); }}>
                <img
                  src="/images/blog/case-waste.png"
                  alt="post 1"
                  className="h-full w-full object-cover transition-transform duration-[500ms] will-change-transform hover:scale-105 md:rounded-l-2xl rounded-l-none rounded-t-2xl md:rounded-t-none"
                />
              </a>
            </figure>

            {/* <!-- Body --> */}
            <div className="dark:bg-jacarta-1000 bg-jacarta-1000 rounded-b-[1.25rem] p-[10%] md:w-1/2 md:rounded-none md:rounded-r-[1.25rem]">
              {/* <!-- Meta --> */}
              <div className="mb-3 flex flex-wrap items-center space-x-1 text-xs">
                <p className="dark:text-jacarta-200 text-jacarta-200">
                  PLAZA VEA
                </p>
              </div>

              <h2 className="font-display mb-4 text-xl dark:text-white text-white sm:text-3xl">
                Turning Waste Into Low Prices
              </h2>
              <p className="dark:text-jacarta-200 text-jacarta-200 mb-6">
                Proyecto que busca aliviar la crisis de fertilizantes a partir de utilizar una nueva fórmula que permite generar fertilizantes de manera más rápida a partir de los residuos orgánicos.
              </p>
              <div className="mb-10"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #RESIDUOS
                  </p>
                </div>
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #FERTILIZANTE
                  </p>
                </div>
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #SOSTENIBILIDAD
                  </p>
                </div>
              </div>

              <div className="inline-flex space-x-4">
                <button onClick={() => { setvideoModal(true); setUrl("https://www.youtube.com/embed/m-vT6b37_7E"); }} className="bg-accent hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-black ease-out duration-200">
                  VER MÁS
                </button>
              </div>
            </div>
          </div>
        </article>

        {/* AWA */}
        <article className="mb-[1.875rem] md:mb-16">
          <div className="rounded-2xl dark:rounded-2xl flex flex-col overflow-hidden md:flex-row hidden-safari">
            {/* <!-- Body --> */}
            <div className="dark:bg-jacarta-1000 bg-jacarta-1000 p-[10%] md:w-1/2 md:rounded-none md:rounded-l-[1.25rem]">
              {/* <!-- Meta --> */}
              <div className="mb-3 flex flex-wrap items-center space-x-1 text-xs">
                <p className="dark:text-jacarta-200 text-jacarta-200">
                  AGUA ANDEA
                </p>
              </div>

              <h2 className="font-display mb-4 text-xl dark:text-white text-white sm:text-3xl">
                AWA
              </h2>
              <p className="dark:text-jacarta-200 text-jacarta-200 mb-6">
                Un proyecto que busca descontaminar los ríos para quienes dependen de aguas no tratadas.
              </p>

              <div className="mb-10"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #JABON
                  </p>
                </div>
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #MICROORGANISMOS
                  </p>
                </div>
                <div
                  style={{
                    padding: '5px 15px',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '15px'
                  }}
                  className="border border-jacarta-200 dark:border-jacarta-200 mr-2">
                  <p className="dark:text-jacarta-200 text-jacarta-200 text-xs">
                    #LIMPIEZA
                  </p>
                </div>
              </div>

              <div className="inline-flex space-x-4">
                <button onClick={() => { setvideoModal(true); setUrl("https://www.youtube.com/embed/H6CJ7leUVG0"); }} className="bg-accent hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-black ease-out duration-200">
                  VER MÁS
                </button>
              </div>

              <div className="flex flex-row mt-10 ">
                <p className="pr-3 dark:text-jacarta-200 text-jacarta-200">PREMIOS</p>

                <Tooltip
                  className="mr-3 tooltip"
                  content={
                    <div className="text-start">
                      <span>Cannes Lions:</span>
                      <br />
                      <span>1x PLATA</span>
                      <br />
                      <span>2x BRONCE</span>
                    </div>
                  }
                  color="invert"
                >
                  <img
                    src="/images/awards/awards/premio-cannesl.svg"
                    alt="awards"
                    className="w-[auto] h-[20px]"
                  />
                </Tooltip>

                <Tooltip
                  className="mr-3 tooltip"
                  content={
                    <div className="text-start">
                      <span>El Ojo de Iberoamérica:</span>
                      <br />
                      <span>2x ORO</span>
                      <br />
                      <span>6x PLATA</span>
                      <br />
                      <span>2x BRONCE</span>
                    </div>
                  }
                  color="invert"
                >
                  <img
                    src="/images/awards/awards/premio-ojo.svg"
                    alt="awards"
                    className="w-[auto] h-[20px]"
                  />
                </Tooltip>

                <Tooltip
                  className="mr-3 tooltip"
                  content={
                    <div className="text-start">
                      <span>Premio Ideas:</span>
                      <br />
                      <span>1x GRAND PRIX</span>
                      <br />
                      <span>4x ORO</span>
                      <br />
                      <span>1x BRONCE</span>
                    </div>
                  }
                  color="invert"
                >
                  <img
                    src="/images/awards/awards/premio-ideas.svg"
                    alt="awards"
                    className="w-[auto] h-[20px]"
                  />
                </Tooltip>

                <Tooltip
                  className="mr-3 tooltip"
                  content={
                    <div className="text-start">
                      <span>LIA Awards:</span>
                      <br />
                      <span>1x ORO</span>
                    </div>
                  }
                  color="invert"
                >
                  <img
                    src="/images/awards/awards/premio-lia.svg"
                    alt="awards"
                    className="w-[auto] h-[20px]"
                  />
                </Tooltip>

                <Tooltip
                  className="mr-3 tooltip"
                  content={
                    <div className="text-start">
                      <span>D&AD Pencils:</span>
                      <br />
                      <span>2x GRAPHITE</span>
                    </div>
                  }
                  color="invert"
                >
                  <img
                    src="/images/awards/awards/premio-d&ad.svg"
                    alt="awards"
                    className="w-[auto] h-[20px]"
                  />
                </Tooltip>

              </div>
            </div>

            <figure className="group overflow-hidden md:w-1/2 cursor-pointer">
              <a onClick={() => { setvideoModal(true); setUrl("https://www.youtube.com/embed/H6CJ7leUVG0"); }}>
                <img
                  src="/images/blog/case-awa.png"
                  alt="post 1"
                  className="h-full w-full object-cover transition-transform duration-[500ms] will-change-transform hover:scale-105 md:rounded-r-2xl rounded-r-none rounded-b-2xl md:rounded-b-none"
                />
              </a>
            </figure>
          </div>
        </article>

        {/* ----------------------------------------------------------------------------------------------------------------------- */}
        {/* ----------------------------------------------------------------------------------------------------------------------- */}

        {/* CALL TO ACTION */}
        <article className="mb-[1.875rem] md:mb-16 dark:bg-jacarta-1000 bg-jacarta-1000 rounded-[1.25rem] flex flex-col place-items-center">

          <h2 className="font-display pt-16 text-center self-center mb-4 text-xl dark:text-white text-white sm:text-3xl px-10">
            ¿Interesado en los proyectos de Cirsys?
          </h2>

          <p className="dark:text-jacarta-200 text-jacarta-200 mt-4 mb-12 text-center self-center">
            Descubre más desarrollos de nuestra empresa.
          </p>

          <div className="pb-16">
            <Link href="/proyectos">
              <a className="bg-accent hover:bg-accent-dark w-58 rounded-full py-3 px-8 text-center font-semibold text-black transition-all ease-out duration-200">
                VER MÁS PROYECTOS
              </a>
            </Link>
          </div>

        </article>

      </div>

      <Calltoaction />

      <Promo />

      {/* CONTATO */}
      <section className="dark:bg-jacarta-1000 bg-jacarta-1000 relative py-24">
        <picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="gradient"
            className="h-full w-full"
          />
        </picture>
        <div className="container">
          <div className="lg:flex">
            {/* <!-- Contact Form --> */}
            <div className="mb-12 lg:mb-0 lg:w-2/3 lg:pr-12">
              <h2 className="font-display mb-4 text-4xl dark:text-white text-white">
                Contacto
              </h2>
              <p className="dark:text-jacarta-300 mb-16 text-lg leading-normal text-jacarta-300">
                {
                  "¿Tienes una pregunta? ¿Necesitas ayuda? No dudes en escribirnos."
                }
              </p>

              <ContactForm />
            </div>

            {/* <!-- Info --> */}
            <Address />
          </div>
        </div>
      </section>


      {/* <!-- YouTube Video Modal --> */}
      <div
        onClick={() => {
          setvideoModal(false);
          setUrl(null);
        }}
        className={
          videoModal
            ? "modal lightbox fade show block dark:bg-jacarta-900/90 bg-jacarta-900/90 cursor-default"
            : "modal lightbox fade"
        }
      >
        <div className="modal-dialog modal-dialog-centered modal-xl w-full ">
          <div className="modal-content border-0 bg-transparent ">
            <div className="modal-body p-0 relative ">
              <button
                onClick={() => {
                  setvideoModal(false);
                  setUrl(null);
                }}
                type="button"
                className="btn-close position-absolute top-0 end-0 p-3 z-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#fff"
                  className="h-6 w-6"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              <div
                id="lightboxCarousel-d7ewe4ig"
                className="lightbox-carousel carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="position-absolute top-50 start-50 translate-middle text-white">
                      <div
                        className="spinner-border"
                        role="status"
                      ></div>
                    </div>
                    <div className="ratio ratio-16x9">
                      <iframe
                        src={url}
                        title="YouTube video player"
                        allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home_9;
