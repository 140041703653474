import Link from "next/link";
import { Partners } from "../component";

const Hero_4 = () => {
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-32 hero-fix"
        style={{
          height: '120vh',
          minHeight: '1250px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:block w-screen h-screen"
          style={{
            height: '100%',
          }}
        >
          <img src="/images/home-bg.png" alt="cirsys background" className="w-screen"
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '100%',
              height: '100%'
            }}
          />
        </picture>

        <div className="container"
          style={{
            height: '100%'
          }}
        >
          <div className="mx-auto max-w-3xl pt-36 text-center">

            <h1 className="mb-10 font-display text-5xl text-white dark:text-white lg:text-6xl xl:text-7xl">
              Soluciones tecnológicas con impacto
              <span className="animate-gradient">  social y ambiental.</span>
            </h1>

            <p className="text-xl text-jacarta-200 dark:text-jacarta-200">Utilizamos un enfoque ágil y proponemos el uso de la tecnología en beneficio del medio ambiente y la sociedad.</p>

            <div className="flex justify-center items-center mt-10">
              <div className="grid grid-cols-1 sm:grid-cols-2">
                <div
                className="sm:mr-5 mr-0 "
                  style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                >
                  <Link href="/proyectos" >
                    <a
                      className="bg-accent hover:bg-accent-dark w-40 rounded-full py-3 px-8 text-center font-semibold text-black transition-all ease-out duration-200 ">
                      PROYECTOS
                    </a>
                  </Link>
                </div> 

                <div
                className="mt-10 sm:mt-0"
                  style={{justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                >
                  <Link href="/contacto">
                    <a className="border hover:text-black hover:bg-accent-dark w-40 rounded-full py-3 px-8 text-center font-semibold text-accent transition-all ease-out duration-200">
                      CONTACTO
                    </a>
                  </Link>
                </div>

              </div>
            </div>

          </div>
        </div>

        <Partners />
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_4;
