import Link from "next/link";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Partners } from "../component";
import Awards from "../partners/Awards";

const Promo = () => {
    const [videoModal, setvideoModal] = useState(false);
    const [url, setUrl] = useState(null);
    return (
        <>
            {/* <!-- Promo --> */}
            <section className="relative dark:bg-jacarta-1100 bg-jacarta-1100 py-44">
                <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden hidden">
                    <img
                        src="/images/gradient_light.jpg"
                        alt="gradient"
                        className="h-full w-full"
                    />
                </picture>
                <div className="container">
                    <div className="lg:flex lg:justify-between">
                        {/* <!-- Image --> */}
                        <div className="relative lg:w-[55%]">
                            <img
                                src="/images/patterns/pattern_circle_1.png"
                                className="absolute -bottom-4 -left-8 animate-fly dark:opacity-10 opacity-10"
                                alt=""
                            />
                            <img
                                src="/images/patterns/pattern_circle_2.png"
                                className="absolute -top-14 right-0 animate-fly dark:opacity-10 opacity-10 md:-right-12"
                                alt=""
                            />
                            <div className="flex items-center space-x-7">
                                <figure className="relative">
                                    <img
                                        src="/images/about/about-cirsys.png"
                                        className="rounded-3xl"
                                        alt=""
                                    />
                                </figure>
                                <figure className="relative overflow-hidden rounded-3xl before:absolute before:inset-0 before:bg-jacarta-900/25">
                                    <button
                                        onClick={() => {
                                            setvideoModal(true);
                                            setUrl(
                                                "https://www.youtube.com/embed/IWMlzZYu3ss"
                                            );
                                        }}
                                        className="js-video-modal-trigger absolute top-1/2 left-1/2 flex h-16 w-16 -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full border-2 border-white transition-transform will-change-transform hover:scale-90 ease-out duration-300"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            className="h-8 w-8 fill-white"
                                        >
                                            <path
                                                fill="none"
                                                d="M0 0h24v24H0z"
                                            />
                                            <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                                        </svg>
                                    </button>
                                    <img
                                        src="/images/about/about-video.png"
                                        alt=""
                                    />
                                </figure>
                            </div>
                        </div>

                        {/* <!-- Info --> */}
                        <div className="py-10 lg:w-[45%] lg:pl-28">
                            <h2 className="mb-6 font-display text-3xl dark:text-white text-white">
                                Acerca de CIRSYS
                            </h2>
                            <p className="mb-8 text-lg leading-normal dark:text-jacarta-300 text-jacarta-300">
                                En CIRSYS buscamos crear tecnología para un mundo más sostenible. Somos una empresa que aporta valor a los negocios desarrollando proyectos con Inteligencia Artificial, Robotica Social, Automatización, desarrollo de Hardware y Software.
                            </p>
                            <div className="inline-flex space-x-4">
                                <Link href="/acerca">
                                    <a className="bg-accent hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-black transition-all">
                                        VER MÁS
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- end promo --> */}

            <Awards />

            {/* <!-- YouTube Video Modal --> */}
            <div
                onClick={() => {
                    setvideoModal(false);
                    setUrl(null);
                }}
                className={
                    videoModal
                        ? "modal lightbox fade show block dark:bg-jacarta-900/90 bg-jacarta-900/90 cursor-default"
                        : "modal lightbox fade"
                }
            >
                <div className="modal-dialog modal-dialog-centered modal-xl w-full ">
                    <div className="modal-content border-0 bg-transparent ">
                        <div className="modal-body p-0 relative ">
                            <button
                                onClick={() => {
                                    setvideoModal(false);
                                    setUrl(null);
                                }}
                                type="button"
                                className="btn-close position-absolute top-0 end-0 p-3 z-10"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                    fill="#fff"
                                    className="h-6 w-6"
                                >
                                    <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                                </svg>
                            </button>
                            <div
                                id="lightboxCarousel-d7ewe4ig"
                                className="lightbox-carousel carousel"
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="position-absolute top-50 start-50 translate-middle text-white">
                                            <div
                                                className="spinner-border"
                                                role="status"
                                            ></div>
                                        </div>
                                        <div className="ratio ratio-16x9">
                                            <iframe
                                                src={url}
                                                title="YouTube video player"
                                                allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                                            ></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Promo;
